import breakpoints from 'lib/club-theme/breakpoints';
import { useEffect, useState } from 'react';

// Adapted from https://upmostly.com/tutorials/how-to-use-media-queries-in-react
const useBreakpoint = (breakpoint = 'fullHeader', widthType = 'max') => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const query = `(${widthType}-width: ${breakpoints[`${breakpoint}`]}px)`;
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [breakpoint, widthType]);

  return matches;
};

export default useBreakpoint;
