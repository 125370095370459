import React from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import {
  AbsoluteContainer,
  Button,
  Cushion,
  Grid,
  Panel,
  Rectangle,
  RelativeContainer,
  ScrollBox,
  Shadow,
  Space,
  TextOverflow,
  Typography,
  withTheme,
} from '@pitchero/react-ui';
import IconWithTheme from 'components/icon-with-theme';
import FacebookIcon from 'icons/Facebook';
import TwitterIcon from 'icons/Twitter';
import Ink from 'react-ink';
import SocialShare from 'lib/social-share';
import { Trans } from 'lib/i18n';

const SharePanel = ({ url, isOpen, onClose, title }) => {
  const content = (
    <Rectangle fill="seashellgrey" component="div" style={{ height: '100%' }}>
      <ScrollBox yOnly>
        <Cushion all="medium">
          <Typography preset="subtitle--large" color="black">
            <Trans i18nKey="common:share_via">Share via</Trans>
          </Typography>

          <Cushion vertical="medium" component="div">
            <Grid
              className="ie-grid-two-col grid-share-icons"
              columns="60px 60px"
              columnGap="medium"
              rowGap="small"
            >
              <Shadow shadow="message">
                <Rectangle fill="facebook" radius={10}>
                  <Cushion all="xsmall">
                    <a
                      role="presentation"
                      onClick={() => SocialShare.facebookShare(window.location.href)}
                      style={{ cursor: 'pointer' }}
                    >
                      <IconWithTheme>
                        <Space left="xxsmall">
                          <FacebookIcon size={44} color="white" />
                        </Space>
                      </IconWithTheme>
                    </a>
                  </Cushion>
                </Rectangle>
              </Shadow>

              <Shadow shadow="message">
                <Cushion all="xsmall">
                  <Rectangle fill="black" radius={10}>
                    <a
                      role="presentation"
                      onClick={() => SocialShare.twitterShare(window.location.href, title)}
                      style={{ cursor: 'pointer' }}
                    >
                      <IconWithTheme>
                        <Space left="xsmall" top="xsmall">
                          <TwitterIcon size={36} color="white" viewBox="0 0 1200 1200" />
                        </Space>
                      </IconWithTheme>
                    </a>
                  </Rectangle>
                </Cushion>
              </Shadow>

              <Typography preset="description" color="dustygrey" style={{ textAlign: 'center' }}>
                Facebook
              </Typography>

              <Typography preset="description" color="dustygrey" style={{ textAlign: 'center' }}>
                X
              </Typography>
            </Grid>
          </Cushion>

          <Cushion vertical="medium" component="div">
            <RelativeContainer>
              <Shadow shadow="message">
                <Cushion horizontal="medium" vertical="small">
                  <Rectangle fill="white" radius={30}>
                    <TextOverflow maxWidth="100%">
                      <Typography preset="body--small-opacity">{url.substr(0, 30)}</Typography>
                    </TextOverflow>
                    <AbsoluteContainer top={7} right={20}>
                      <Rectangle fill="white" style={{ cursor: 'pointer' }}>
                        <Cushion all="xsmall">
                          <Ink background={false} />
                          <Button theme="text" onClick={() => copy(url)}>
                            <Trans i18nKey="common:copy" color="primary">
                              Copy
                            </Trans>
                          </Button>
                        </Cushion>
                      </Rectangle>
                    </AbsoluteContainer>
                  </Rectangle>
                </Cushion>
              </Shadow>
            </RelativeContainer>
          </Cushion>
        </Cushion>
      </ScrollBox>
    </Rectangle>
  );

  return (
    <>
      <Panel isOpen={isOpen} onClose={onClose}>
        {content}
      </Panel>
    </>
  );
};

SharePanel.propTypes = {
  url: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default withTheme(SharePanel);
